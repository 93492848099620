import "./index.scss";

export default function Review({ data, isDisplayReview, index }) {
  const {
    id,
    project,
    reason,
    date,
    duration,
    budget,
    review,
    rating,
    quality,
    schedule,
    cost,
    willingToRefer,
    reviewer,
    name,
    position,
    employees,
    location,
    reviewMethod,
    reviewMethodDescription,
    isVerified,
    projectSummary,
    feedbackSummary,
    metaName,
    metaDate,
  } = data;

  return (
    <div className="views-row" style={{ display: isDisplayReview(index) }}>
      <div
        id={`review-${id}`}
        className="feedback client-interview"
        itemProp="review"
        data-id={id}
        itemScope
        itemType="http://schema.org/Review"
      >
        <meta itemProp="name" content={metaName} />
        <meta itemProp="datePublished" content={metaDate} />
        <div className="review_data--container">
          <div className="row row-custom">
            <div className="review-border">
              <div className="review-mobile-top">
                <div className="col-8 border-right">
                  <div className="review-mobile-top-item">
                    <div
                      className="field-name-project-type field-inline custom_popover custom_popover__left"
                      data-content="<i>Project category</i>"
                      data-popover-position=".icon-wrapper"
                    >
                      <div className="icon-wrapper">
                        <i className="list-icon icon_monitor"></i>
                      </div>

                      <span>{reason}</span>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="review-mobile-top-item">
                    <span className="date">{date}</span>
                  </div>
                </div>
              </div>
              <div className="project-review-col">
                <div className="col-md-4-custom project-col">
                  <h5 className="h5_title">the project</h5>
                  <h2 className="h2_title">
                    <a
                      href={`#review-${id}`}
                      data-toggle="gotoreview"
                      className="inner_url"
                    >
                      {project}
                    </a>
                  </h2>

                  <div className="review-mobile-quote">
                    <div className="field field-name-client-quote field-inline">
                      <div className="field-item">
                        <p>{`"${review}"`}</p>
                      </div>
                    </div>
                  </div>

                  <div className="abs-aligned">
                    <div
                      className="field field-name-project-type field-inline custom_popover custom_popover__left"
                      data-content="<i>Project category</i>"
                      data-popover-position=".icon-wrapper"
                    >
                      <div className="icon-wrapper">
                        <i className="list-icon icon_monitor"></i>
                      </div>
                      <div className="field-item">
                        <span>{reason}</span>
                      </div>
                    </div>

                    <div
                      className="field field-name-cost field-inline custom_popover"
                      data-content="<i>Project size</i>"
                      data-popover-position=".icon-wrapper"
                    >
                      <div className="icon-wrapper">
                        <i className="list-icon icon_tag"></i>
                      </div>
                      <div className="field-item">{budget}</div>
                    </div>

                    {duration ? (
                      <div
                        className="field field-name-project-length field-inline custom_popover"
                        data-content="<i>Project length</i>"
                        data-popover-position=".icon-wrapper"
                      >
                        <div className="icon-wrapper">
                          <i className="list-icon icon_calendar"></i>
                        </div>
                        <div className="field-item">{duration}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="review-mobile-stars">
                    <div className="review-mobile-stars-tp">
                      <button
                        type="button"
                        className="btn review-mobile-top-item-link"
                      >
                        <span className="see-less">see more</span>
                        <i className="arrow"></i>
                      </button>

                      <div className="rating-reviews sg-rating">
                        <span className="rating sg-rating__number">
                          {rating}
                        </span>
                        <span className="reviews-totals-stars sg-rating__stars full"></span>
                      </div>
                    </div>
                  </div>
                  <div className="review-mobile-cp hideon_active">
                    <div className="review-mobile-cp-stars">
                      <div className="field field-name-quality field-inline">
                        <div
                          className="field-label"
                          data-toggle="tooltip"
                          data-placement="auto left"
                          title=""
                          data-original-title=""
                        >
                          Quality:
                        </div>
                        <div className="field-item">{quality}</div>
                      </div>
                      <div className="field field-name-schedule field-inline">
                        <div
                          className="field-label"
                          data-toggle="tooltip"
                          data-placement="auto left"
                          title=""
                          data-original-title=""
                        >
                          Schedule:
                        </div>
                        <div className="field-item">{schedule}</div>
                      </div>
                      <div className="field field-name-cost-feedback field-inline">
                        <div
                          className="field-label"
                          data-toggle="tooltip"
                          data-placement="auto left"
                          title=""
                          data-original-title=""
                        >
                          Cost:
                        </div>
                        <div className="field-item">{cost}</div>
                      </div>
                      <div className="field field-name-willingness-refer field-inline">
                        <div
                          className="field-label"
                          data-toggle="tooltip"
                          data-placement="auto left"
                          title=""
                          data-original-title=""
                        >
                          Willing to refer:
                        </div>
                        <div className="field-item">{willingToRefer}</div>
                      </div>
                    </div>
                    <div className="review-mobile-header">The Project</div>
                    <div className="review-mobile-project">
                      <div
                        className="field field-name-cost field-inline custom_popover"
                        data-content="<i>Project size</i>"
                        data-popover-position=".icon-wrapper"
                      >
                        <div className="icon-wrapper">
                          <i className="list-icon icon_tag"></i>
                        </div>
                        <div className="field-item">{budget}</div>
                      </div>

                      {duration ? (
                        <div
                          className="field field-name-project-length field-inline custom_popover"
                          data-content="<i>Project length</i>"
                          data-popover-position=".icon-wrapper"
                        >
                          <div className="icon-wrapper">
                            <i className="list-icon icon_calendar"></i>
                          </div>
                          <div className="field-item">{duration}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="review-col-sep"></div>
                    <div className="field field-name-proj-description field-inline">
                      <div
                        className="field-label"
                        data-toggle="tooltip"
                        data-placement="auto left"
                        title=""
                        data-original-title=""
                      >
                        Project summary:
                      </div>
                      <div className="field-item">
                        <p>{projectSummary} </p>
                      </div>
                    </div>

                    <div className="review-mobile-header">The Reviewer</div>
                    <div className="review-mobile-reviewer">
                      <div className="review-mobile-project">
                        <div
                          className="field field-name-company-size field-inline custom_popover"
                          data-content="<i>Client size</i>"
                          data-popover-position=".icon-wrapper"
                        >
                          <div className="icon-wrapper">
                            <i className="list-icon icon_person__heavy"></i>
                          </div>
                          <div className="field-item">{employees}</div>
                        </div>

                        <div
                          className="field field-name-company-size field-inline custom_popover"
                          data-content="<i>Location</i>"
                          data-popover-position=".icon-wrapper"
                        >
                          <div className="icon-wrapper">
                            <i className="list-icon icon_pin"></i>
                          </div>
                          <div className="field-item">{location}</div>
                        </div>
                      </div>
                      <div className="review-mobile-reviewer-wrapper">
                        <i className="icon-person"></i>
                      </div>
                      <div className="review-mobile-reviewer-text">
                        <div className="field-name-full-name-display">
                          <div className="field-item">{name}</div>
                        </div>

                        <div className="field-name-title">
                          <div
                            className="field-item"
                            itemProp="author"
                            itemScope
                            itemType="https://schema.org/Person"
                          >
                            <span itemProp="name">{reviewer}</span>
                          </div>
                        </div>

                        <div
                          className="field-name-verified field-inline custom_popover"
                          data-content={`<i>${isVerified}</i>`}
                          data-popover-position=".icon-wrapper"
                        >
                          <div className="icon-wrapper">
                            <i
                              className={`list-icon ${
                                isVerified.trim() === "Verified"
                                  ? "icon_verified"
                                  : "icon_not-verified"
                              }
                            icon_verified`}
                            ></i>
                          </div>
                          <div className="field-item">{isVerified}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-5-custom review-col hideon_active"
                  data-mh="review-col"
                >
                  <div className="row-custom review-col-oh">
                    <div className="col-md-6 col-lg-7 review-col-reviewtxt">
                      <h5 className="h5_title">the review</h5>

                      <div className="field field-name-client-quote field-inline">
                        <div className="field-item">
                          <p>{`"${review}"`}</p>
                        </div>
                      </div>

                      <h5 className="h5_title date">{date}</h5>
                    </div>
                    <div className="col-md-6 col-lg-5 review-col-fdb">
                      <div className="group-feedback">
                        <div className="field field-name-overall-rating">
                          <div className="rating-reviews sg-rating">
                            <span className="rating sg-rating__number">
                              {rating}
                            </span>
                            <span className="reviews-totals-stars sg-rating__stars full"></span>
                          </div>
                        </div>
                        <div className="field field-name-quality field-inline">
                          <div className="field-label">Quality:</div>
                          <div className="field-item">{quality}</div>
                        </div>
                        <div className="field field-name-schedule field-inline">
                          <div className="field-label">Schedule:</div>
                          <div className="field-item">{schedule}</div>
                        </div>
                        <div className="field field-name-cost-feedback field-inline">
                          <div className="field-label">Cost:</div>
                          <div className="field-item">{cost}</div>
                        </div>
                        <div className="field field-name-willingness-refer field-inline">
                          <div className="field-label">Willing to refer:</div>
                          <div className="field-item">{willingToRefer}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="review-mobile-header">The Review</div>

                  <div className="review-col-sep"></div>
                  <div className="field field-name-comments field-inline">
                    <div
                      className="field-label"
                      data-toggle="tooltip"
                      data-placement="auto left"
                      title=""
                      data-original-title=""
                    >
                      Feedback summary:
                    </div>
                    <div className="field-item">
                      <p>{feedbackSummary} </p>
                    </div>
                  </div>

                  <div className="field-items-buttons">
                    <a
                      className="btn btn-collapse collapsed more-review"
                      target="_blank"
                      href={`https://clutch.co/profile/engenious?utm_source=widget&utm_medium=4&utm_campaign=widget&utm_content=stars#reviews#review-${id}`}
                      data-ga-action="read_full_review_click"
                      rel="noreferrer"
                    >
                      <i className="arrow"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-md-3-custom reviewer-col"
                data-mh="review-col"
              >
                <h5 className="h5_title">the reviewer</h5>
                <div className="group-interview">
                  <div className="group-reviewer">
                    <div className="field-name-title">{reviewer}</div>
                    <div className="field-name-full-name-display">
                      <span className="icon-person"></span>

                      <div className="field-item">{name}</div>
                    </div>
                  </div>

                  <div
                    className="field-name-user-industry field-inline custom_popover"
                    data-content="<i>Industry</i>"
                    data-popover-position=".icon-wrapper"
                  >
                    <div className="icon-wrapper">
                      <i className="list-icon icon_industry"></i>
                    </div>
                    <span className="field-item">{position}</span>
                  </div>

                  <div
                    className="field-name-company-size field-inline custom_popover"
                    data-content="<i>Client size</i>"
                    data-popover-position=".icon-wrapper"
                  >
                    <div className="icon-wrapper">
                      <i className="list-icon icon_person__heavy"></i>
                    </div>
                    <span className="field-item">{employees}</span>
                  </div>

                  <div
                    className="field-name-location field-inline custom_popover"
                    data-content="<i>Location</i>"
                    data-popover-position=".icon-wrapper"
                  >
                    <div className="icon-wrapper">
                      <i className="list-icon icon_pin"></i>
                    </div>
                    <span className="field-item">{location}</span>
                  </div>

                  <div
                    className="field-name-review-type text-tip field-inline custom_popover"
                    data-content="<i>Review type</i>"
                    data-popover-position=".icon-wrapper"
                  >
                    <div className="icon-wrapper">
                      <i className="list-icon icon_phone__survey"></i>
                    </div>

                    <div className="field-item">
                      {reviewMethod}
                      <span className="clutch-pp-tip">
                        <span className="clutch-pp-tip-text">
                          <span>{reviewMethodDescription}</span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    className="field-name-verified field-inline custom_popover"
                    data-content={`<i>${isVerified}</i>`}
                    data-popover-position=".icon-wrapper"
                  >
                    <div className="icon-wrapper">
                      <i
                        className={`list-icon ${
                          isVerified.trim() === "Verified"
                            ? "icon_verified"
                            : "icon_not-verified"
                        }
                            icon_verified`}
                      ></i>
                    </div>
                    <div className="field-item">{isVerified}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
