import { useInView } from "react-intersection-observer";
import {  useRef } from "react";
import classes from "./styles.module.scss";
import "./animation.css";
import buttonClasses from "../../../components/commons/Button.module.scss";
import { Link } from "react-router-dom";




const Card = ({ expertise, index }) => {
  const imageContainerRef = useRef();
  const imageConatinerMobileRef = useRef();
  const { ref } = useInView({ rootMargin: "-30% 0px" });


  return (
    <section className={`${classes.expertise__card}`} ref={ref}>
      <div className={classes.expertise__text}>
        <h2 className={classes.expertise__title}>{expertise.title}</h2>
        <p className={classes.expertise__description}>
          {expertise.description}
        </p>
        <div
          className={classes.expertise__image_mobile}
          ref={imageConatinerMobileRef}
        >
          <img src={expertise.imageMobile} alt=""/>
        </div>
        <div className={classes.expertise__button}>
          <Link
            data-content="Read more"
            to={expertise.link}
            className={`${buttonClasses.button}`}
          >
            Read more
          </Link>
        </div>
      </div>
      <div className={classes.expertise__image} ref={imageContainerRef}>
        <img src={expertise.image} alt=""/>
      </div>
    </section>
  );
};

export default Card;
