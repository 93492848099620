import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

gsap.config({
  force3D: false,
  nullTargetWarn: false,
});

const timeline = () => gsap.timeline({ paused: true });

function fadeIn(elem, options = {}) {
  const tl = gsap.timeline();
  tl.to(elem, {
    autoAlpha: 1,
    ...options,
  });

  return tl;
}

function zoomIn(elem, options = {}) {
  const tl = gsap.timeline();
  tl.to(elem, {
    scale: 1,
    ...options,
  });

  return tl;
}

function zoomOut(elem, options = {}) {
  const tl = gsap.timeline();
  tl.to(elem, {
    scale: 0,
    ...options,
  });

  return tl;
}

function slideIn(elem, options = {}) {
  const tl = gsap.timeline();
  tl.to(elem, {
    x: 0,
    y: 0,
    ...options,
  });

  return tl;
}

function scrollTo(elem, options = {}) {
  const tl = gsap.timeline();
  tl.to(elem, {
    ...options,
  });

  return tl;
}

export { zoomIn, slideIn, fadeIn, timeline, zoomOut, scrollTo };
