export const data = [
  {
    id: 1988807,
    project: `Custom Software Dev for Staffing & Recruiting Company`,
    reason: `Custom Software Development`,
    date: "JUL 17, 2022",
    duration: "Jan. - Nov. 2021",
    budget: "$200,000 to $999,999",
    review: `The team was able to translate all our desires to the development team.`,
    rating: 5,
    quality: 5,
    schedule: 5,
    cost: 5,
    willingToRefer: 5,
    reviewer: `CEO, MrGofer`,
    name: "",
    position: `Human Resources`,
    employees: `51-200 Employees`,
    location: `Manila, Philippines`,
    reviewMethod: `Online Review`,
    reviewMethodDescription: `The client submitted this review online.`,
    isVerified: `Verified`,
    projectSummary: `A staffing and recruiting company hired Agilistry to develop a custom CRM with a cohesive team of engineers and designers. The team utilized Node.JS and ReactJS for the platform's development.`,
    feedbackSummary: `Agilistry successfully developed custom software that allowed the client to generate leads, track time, create strategic plans and internal resources, and track vacations. Their professionalism and passion were notable in partnership. The client also commended the team's design approach.
    `,
    metaName: ` Custom Software Dev for Staffing & Recruiting Company`,
    metaDate: `July 17, 2022`,
  },
  {
    id: 1987933,
    metaName: ` Website Development for Flight Award Service Company `,
    metaDate: `July 14, 2022`,
    project: `Website Development for Flight Award Service Company`,
    reason: `Web Development`,
    budget: `$200,000 to $999,999`,
    duration: `June 2020 - Ongoing`,
    date: `JUL 14, 2022`,
    review: `The team has strong management and skilled developers.`,
    rating: 5,
    quality: 5,
    schedule: 5,
    cost: 5,
    willingToRefer: 5,
    reviewer: `Director of Operations, AwardLogic`,
    name: "Gene Khizver",
    position: `Consumer Products`,
    employees: `51-200 Employees`,
    location: `Miami, Florida`,
    reviewMethod: `Online Review`,
    reviewMethodDescription: `The client submitted this review online.`,
    isVerified: `Verified`,
    projectSummary: `Agilistry has built a website from scratch using Java, Node.js, and Redis for a flight award service company. The team has initially outlined the sprints and deliverables and created the necessary features.`,
    feedbackSummary: `While the engagement is ongoing, Agilistry thus far has developed a reliable and fast search logic and seamless UI/UX designs. The team dedicatedly follows an agile methodology and communicates through biweekly demos. Moreover, their robust management style, creativity, and proficiency are notable.`,
  },
  {
    id: 1553984,
    metaName: ` Regression Testing Automation for Digital Writing Assistant `,
    metaDate: `October 7, 2020`,
    project: `Regression Testing Automation for Digital Writing Assistant`,
    reason: `Development`,
    budget: `Confidential`,
    duration: `Nov. 2019 - May 2020`,
    date: `OCT 7, 2020`,
    review: `The fact that we finished the project on time speaks a lot to their proactivity.`,
    rating: 5,
    quality: 5,
    schedule: 5,
    cost: 5,
    willingToRefer: 5,
    reviewer: `Engineering Manager, Grammarly`,
    name: "Kateryna Vynogradna",
    position: `IT Services`,
    employees: `201-500 Employees`,
    location: `Ukraine`,
    reviewMethod: `Phone Interview`,
    reviewMethodDescription: `A Clutch analyst personally interviewed this client over the phone.`,
    isVerified: `Verified`,
    projectSummary: `A digital writing assistant hired Agilistry to perform regression testing. The team created a test suite and UI framework for the company. They provided their own tool to assist with monitoring tests. `,
    feedbackSummary: `The team at Agilistry delivered on expectations. They're responsive, skilled, and timely. Their high level of communication is also noteworthy. Customers can expect a team of engineers that bring value to their engagements.`,
  },
  {
    id: 1541708,
    metaName: ` E-Commerce Dev for Software Dev Company `,
    metaDate: `September 24, 2020`,
    project: `E-Commerce Dev for Software Dev Company`,
    reason: `E-commerce Development`,
    budget: `$50,000 to $199,999`,
    duration: `Dec. 2019 - Ongoing`,
    date: `SEP 24, 2020`,
    review: `They have absolutely met our expectations from working with the external team.`,
    rating: 5,
    quality: 5,
    schedule: 5,
    cost: 5,
    willingToRefer: 5,
    reviewer: `Product Manager, Software Development Company`,
    name: `Iaroslav Krupei`,
    position: `IT Services`,
    employees: `1-10 Employees`,
    location: `Los Angeles, California`,
    reviewMethod: `Online Review`,
    reviewMethodDescription: `The client submitted this review online.`,
    isVerified: `Not Verified`,
    projectSummary: `Agilistry provided e-commerce website development for a software development company. The team focused on adding features like Stripe payment and an informative dashboard.`,
    feedbackSummary: `Agilistry managed to deliver valuable features for the client which helped in onboarding a lot of new clients. Their team was reliable enough to manage the development process on their own. They were able to clearly understand the scope of the project.`,
  },
  {
    id: 1527969,
    metaName: ` IT Management & Testing for IT Company `,
    metaDate: `September 9, 2020`,
    project: `IT Management & Testing for IT Company`,
    reason: `Managed IT Services Application Testing`,
    budget: `$200,000 to $999,999`,
    duration: `Apr. 2019 - Ongoing`,
    date: `SEP 9, 2020`,
    review: `It’s easy to digest their information because the outcome is always logical`,
    rating: 4.5,
    quality: 5,
    schedule: 5,
    cost: 4.5,
    willingToRefer: 5,
    reviewer: `Business Development Partner, IT Company`,
    name: ``,
    position: `IT Services`,
    employees: `201-500 Employees`,
    location: `Chicago, Illinois`,
    reviewMethod: `Phone Interview`,
    reviewMethodDescription: `A Clutch analyst personally interviewed this client over the phone.`,
    isVerified: `Verified`,
    projectSummary: `Agilistry provides outsourced software testing for a large-scale solution. Their main responsibility are developing testing schemes to identify the end client’s technical issues and consulting on solutions.`,
    feedbackSummary: `So far, Agilistry has led a smooth engagement, delivering on time and sticking to the budget. Their 10-person team of experts maintains transparency through overcommunication, and they’re always available to walk internal staff through how to approach tasks.`,
  },
  {
    id: 1415508,
    metaName: ` Testing and Development Support for Internet Company `,
    metaDate: `May 19, 2020`,
    project: `Testing and Development Support for Internet Company`,
    reason: `Application Testing Application Management & Support`,
    budget: `$50,000 to $199,999`,
    duration: `Jan. 1970 - Ongoing`,
    date: `May 19, 2020`,
    review: `They ask how they can improve and offer their help in any way we need it.`,
    rating: 5,
    quality: 5,
    schedule: 5,
    cost: 5,
    willingToRefer: 5,
    reviewer: `Sr. Software QA Lead Engineer, AnchorFree`,
    name: `Sergei L.`,
    position: `IT Services`,
    employees: `201-500 Employees`,
    location: `San Francisco, California`,
    reviewMethod: `Phone Interview`,
    reviewMethodDescription: `A Clutch analyst personally interviewed this client over the phone.`,
    isVerified: `Verified`,
    projectSummary: `Agilistry helped create a continuous delivery pipeline for an internet company. They taught the client the "shift left" testing approach and automated parts of the pipeline to connect with testing tools.`,
    feedbackSummary: `The automation and integrations have saved the client time and money on testing processes without sacrificing quality. Agilistry demonstrates a high level of expertise and customer service. Their openness to feedback, clear communication, and grasp of the requirements stand out.`,
  },
  {
    id: 1993589,
    metaName: ` Automated Test Suite Development for IT Services Company `,
    metaDate: `July 26, 2022`,
    project: `Automated Test Suite Development for IT Services Company`,
    reason: `Development`,
    budget: `Confidential`,
    duration: `Jan. 2020 - Ongoing`,
    date: `JUL 26, 2022`,
    review: `Agilistry's solutions and engineers are top-notch.`,
    rating: 5,
    quality: 4.5,
    schedule: 5,
    cost: 5,
    willingToRefer: 5,
    reviewer: `Project Lead, IT Services Company`,
    name: ``,
    position: `IT Services`,
    employees: `10,000+ Employees`,
    location: `Austin, Texas`,
    reviewMethod: `Online Review`,
    reviewMethodDescription: `The client submitted this review online.`,
    isVerified: `Not Verified`,
    projectSummary: `An IT services company hired Agilistry to restructure existing automated testing for macOS and iOS. The team built a new testing framework in Swift and a screen model, turning the client's expectations to life.`,
    feedbackSummary: `Thus far, Agilistry has successfully provided enhanced solutions to the client's emerging needs. The team streamlines fluid workflow and responsive communication of feedback, requests, and deliverables, ensuring to meet all expectations. Furthermore, their engineering skills are unparalleled.`,
  },
  {
    id: 1992646,
    metaName: ` Managed IT Services for Online Flight Search Engine `,
    metaDate: `July 25, 2022`,
    project: `Managed IT Services for Online Flight Search Engine`,
    reason: `Managed IT Services Web Development Web design`,
    budget: `$1,000,000 - $9,999,999`,
    duration: `Jan. 2019 - Mar. 2020`,
    date: `JUL 25, 2022`,
    review: `Overall, we were very satisfied with their services.`,
    rating: 5,
    quality: 5,
    schedule: 5,
    cost: 5,
    willingToRefer: 5,
    reviewer: `Director of Operations, FlightGuru`,
    name: `Gene Khizver`,
    position: `Hospitality & Leisure`,
    employees: `501-1,000 Employees`,
    location: `San Francisco, California`,
    reviewMethod: `Online Review`,
    reviewMethodDescription: `The client submitted this review online.`,
    isVerified: `Not Verified`,
    projectSummary: `Agilistry provided managed IT services to an online flight search engine to manage non-trivial ticket search tasks. They built a customized team for the project, and the client interviewed each team member.`,
    feedbackSummary: `The client's platform was the main outcome of the project handled by Agilistry. The client was impressed by the platform's effectiveness, which was indicated by their several internal metrics for search inquiry speed. They also lauded the vendor's strong engineering team and regular updates.`,
  },
  {
    id: 1983565,
    metaName: ` UX Redesign & Site Optimization & Migration for Puzzle Co `,
    metaDate: `July 6, 2022`,
    project: `UX Redesign & Site Optimization & Migration for Puzzle Co`,
    reason: `Custom Software Development Web Development UI/UX Design`,
    budget: `$50,000 to $199,999`,
    duration: `Jan. 2021 - Ongoing`,
    date: `JUL 6, 2022`,
    review: `I am very impressed with the team and how diligent they are about the details.`,
    rating: 5,
    quality: 5,
    schedule: 5,
    cost: 4.5,
    willingToRefer: 5,
    reviewer: `COO, UGEARS USA`,
    name: `Olga Dorovskikh`,
    position: `Retail`,
    employees: `1-10 Employees`,
    location: `Delray Beach, Florida`,
    reviewMethod: `Online Review`,
    reviewMethodDescription: `The client submitted this review online.`,
    isVerified: `Not Verified`,
    projectSummary: `A 3D puzzle company has hired Agilistry to optimize their website's performance by redesigning the UX and migrating the site to AWS. They've also integrated Google Analytics to track user behavior and sales. `,
    feedbackSummary: `The new UX and improved site performance have increased the client's site retention rate by 30%. Although Agilistry doesn't manage the project directly, their team has adapted well to the client's iterative development cycles and scrum methodologies. Their team has excellent attention to detail.`,
  },
  {
    id: 1335693,
    metaName: ` App Development for Market Research Tool  `,
    metaDate: `February 28, 2020`,
    project: `App Development for Market Research Tool`,
    reason: `Mobile App Development`,
    budget: `$10,000 to $49,999`,
    duration: ``,
    date: `FEB 28, 2020`,
    review: `It was complex and we made changes and improvements because of them.`,
    rating: 5,
    quality: 5,
    schedule: 5,
    cost: 5,
    willingToRefer: 5,
    reviewer: `QA Manager, Fuel Cycle`,
    name: `Alex Tuaev`,
    position: `Software`,
    employees: `51-200 Employees`,
    location: `Los Angeles, California`,
    reviewMethod: `Phone Interview`,
    reviewMethodDescription: `A Clutch analyst personally interviewed this client over the phone.`,
    isVerified: `Verified`,
    projectSummary: `Agilistry worked on iOS and Android development for a firm, working with Espresso for Android and XCUITest for iOS. They also provided valuable suggestions on development flow, helping implement the changes.`,
    feedbackSummary: `The team at Agilistry delivered what was promised and went the extra mile to provide assistance outside of scope. Their suggestions were invaluable as was their teaching assistance. There weren't any meaningful delays nor were there any issues with communication between the two teams.`,
  },
];
