import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import buttonClasses from "../../../components/commons/Button.module.scss";
import animationClasses from "../animations/index.module.scss";
import { ReactComponent as Robot } from "../../../assets/img/Home/hero/doubleRobot.svg";
import Download from "../../../assets/img/Home/hero/download_light.svg";
export default function Hero({ iso, heroRef }) {
  return (
    <section ref={heroRef} className={`${classes.section}`}>
      <div className={classes.body}>
        <div
          className={`${classes["slide-left"]} ${animationClasses["slide-left"]}`}
        >
          <div className={classes.content}>
            <h1 className={classes.title}>YOUR RELIABLE BOUTIQUE IT PARTNER</h1>
            <p className={classes.text}>
              Mobile Development Company with the best traditions from Silicon Valley. The one-stop destination for any business from a small startup to a large enterprise.
            </p>
            <div className={classes.row}>
              <Link
                data-content="Contact us"
                to="/contact-us"
                className={`${buttonClasses.button} ${classes.button}`}
              >
                Contact us
              </Link>
              <a
                className={`${classes.download}`}
                href="https://drive.google.com/file/d/1GtN_fvVmbKSIg8BDZz9VH0iT_NVO3eJu/view?usp=drive_link"
                target={"_blank"}
                rel={"noreferrer"}
                download
              >
                <img src={Download} alt="" /> Presentation
              </a>
            </div>
            <div className={classes.helper}>
              <a className={classes.iso} href="/pdf/iso-sertificat.pdf" download>
                <img src={iso} alt="iso" />
              </a>
            </div>
          </div>

        </div>
        <div className={`${classes["slide-right"]}`}>
          <Robot />
        </div>
      </div>
    </section>
  );
}
