import Expertise from "./rest2.png";
import ExpertiseMobile from "./restMobile.png";

export const EXPERTISEREST = {
  title: "Agilistry for restaurants",
  description: `Are you looking to shake up the restaurant industry? We can help you create a solution that will set you apart from the competition. We understand that each business is unique. That's why, we offer a free consultation, providing valuable insights and guidance on how to turn your idea into a successful business. One of our successful projects was Puzzle Pizza, where we designed a Kitchen Display System (KDS) that allowed customers to customize their pizzas by adding various flavors, all in one pizza. With Agilistry, you can take the first step towards creating a revolution in the restaurant industry. Click here to learn more.`,
  image: Expertise,
  imageMobile: ExpertiseMobile,
  link: "/restaurants",
};
