export const DEFAULT_TITLE =
  "Application (IOS/Android) development company | USA 💻 Agilistry";
export const DEFAULT_DESCRIPTION =
  "E2E Mobile iOS/Android applications & Web development from scratch - consulting, design, backend infrastructure, scalable CI/CD processes, testing, and more.";

export const ABOUT_TITLE = "About Us - Agilistrysoft"

export const ABOUT_DESCRIPTION = "Learn more about our company, our history and our team"

export const HOME_DESCRIPTION =
  "Looking for a USA web application development company and solutions company? ✅ Development of complex mobile apps for IOS/Android. Professional implementation of automation, functionality in sites, apps. Ready-made IT solutions for business! Agilistry Inc. is always near me!";

export const CASES_TITLE =
  "Cases of IT company for web (SААS) solutions, application development✅Agilistry";
export const CASES_DESCRIPTION =
  'Our best cases are a full-cycle IT company "Agilistry" for automation and development of mobile applications, sites, web resources. Years of experience - high quality!';

export const SERVICES_TITLE =
  "Services Apps - Automation, testing, and Development at Agilistry";
export const SERVICES_DESCRIPTION =
  "Test automation helps to solve important problems of applications - directly for Android, iOS and Windows, web resources sites. ✅ A set of services for applications from at Agilistry.";

export const SOLUTIONS_TITLE =
  "IT (SAAS) Solution - near me in USA | for Business ✔ Agilistry";
export const SOLUTIONS_DESCRIPTION =
  "IT Solution - near me in USA ✅ SaaS (Software as a Service) is a model for using business applications in the format of Internet services. Only proven developments and technologies at affordable prices from Agilistry";

export const VACANCIES_TITLE =
  "Job Web Development 💼- vacancies, salaries, freelancers | Agilistry";
export const VACANCIES_DESCRIPTION =
  "Web development job, remotely for web programmers, freelancers. ⭐ Vacancies - Job descriptions - Salary";

export const NEXTRES_CASE_TITLE = "Loan servicing software case study";
export const NEXTRES_CASE_DESCRIPTION =
  "In this case study we describe how Agilistry team helped a private lender company to implement relevant digital solutions. An example of this case will help you decide on the implementation of the loan servicing software.";
