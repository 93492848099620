import React, { useEffect } from "react";
import "./index.css";

export const Popup = ({ component, open, openPopup }) => {
  const body = document.querySelector("body");

  useEffect(() => {
    body.classList.toggle("_no-scroll", open);

    return () => body.classList.remove("_no-scroll");
  }, [body.classList, open]);

  return (
    <div
      className="popup"
      style={{ display: open ? "flex" : "none" }}
      onClick={openPopup}
    >
      <div className="close-block">
        <button className="button-close" title="Close">
          <svg
            onClick={openPopup}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="22"
          >
            <path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"></path>
          </svg>
        </button>
      </div>
      <div className="content_block">{open && component}</div>
    </div>
  );
};
