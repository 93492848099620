import { v4 as uuid } from "uuid";
import Card from "./Card";
import { EXPERTISES } from "./data.js";
import classes from "./styles.module.scss";

const Expertise = () => {
  return (
    <div className={classes.expertise}>
      {EXPERTISES.map((expertise, index) => (
        <Card key={uuid()} expertise={expertise} index={index} />
      ))}
    </div>
  );
};

export default Expertise;
