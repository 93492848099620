import classes from "./index.module.scss";
import { ReactComponent as PartnersTop } from "./partners_top.svg";
import { ReactComponent as PartnersBottom } from "./partners_bottom.svg";
import { ReactComponent as Mobile } from "./partnersMobile.svg";

const Partners = () => {
  return (
    <section className={classes.section}>
      <h2 className={classes.title}>trusted by:</h2>
      <div className={`${classes.image}`}>
          <PartnersTop />
          <PartnersBottom />
      </div>
        <div className={`${classes.image} ${classes.image_mobile}`}>
            <Mobile />
        </div>
    </section>
  );
};

export default Partners;
