import classes from "./index.module.scss";
import Partners from "../Partners";

export default function WhoWeAre({ data }) {
  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.content}>
            <Partners />
          </div>
        </div>
      </div>
    </section>
  );
}
