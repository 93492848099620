import classes from "../Expertise/styles.module.scss";
import "./index.scss";

import { EXPERTISEREST } from "./data";
import { Link } from "react-router-dom";
import buttonClasses from "../../../components/commons/Button.module.scss";


const ExpertiseRestaurants = () => {
  const expertise = EXPERTISEREST;
  return (
    <div className={classes.expertise}>
      <section
        className={`${classes.expertise__card} ${classes.expertise__card__rest} `}
      >
        <div
          className={`${classes.expertise__text} ${classes.expertise__card__rest__text}`}
        >
          <h2 className={`${classes.expertise__title}`}>{expertise.title}</h2>
          <div
            className={` ${classes.expertise__card__rest__description}`}
            dangerouslySetInnerHTML={{ __html: `${expertise.description}` }}
          ></div>
          <div
            className={`${classes.expertise__image_mobile} ${classes.expertise__card__rest__image_mobile}`}
          >
            <img src={expertise.imageMobile} alt="" />
          </div>
          <div className={classes.expertise__button}>
            <Link
              data-content="LEARN MORE"
              to={expertise.link}
              className={`${buttonClasses.button}`}
            >
              LEARN MORE
            </Link>
          </div>
        </div>
        <div
            className={`${classes.expertise__image} ${classes.expertise__image_rest}`}>
          <img src={expertise.image} alt="" />
        </div>
      </section>
    </div>
  );
};

export default ExpertiseRestaurants;
