import Cases from '../../../assets/img/Home/expertise/cases_image2.png'
import CasesMobile from '../../../assets/img/Home/expertise/casesMobile.png'
import Services from '../../../assets/img/Home/expertise/service_image.svg'
import ServicesMob from '../../../assets/img/Home/expertise/services_mobile.svg'


export const EXPERTISES = [
  {
    title: "cases",
    description:
      "We would like to share our clients’ success stories where we’ve helped different sized businesses to deliver iOS and Android mobile solutions on time while optimizing their delivery cycles and improving core architecture",
    image: Cases,
    imageMobile: CasesMobile,
    link: "/cases",
  },
  {
    title: "services",
    description:
      "We are ready to help you by leveraging our core competencies in AI, Mobile development, Mobile native automation, CI/CD infrastructure, and Web development",
    image: Services,
    imageMobile: ServicesMob,
    link: "/services",
  },
];
