import {ReactComponent as DataScience} from "../../../assets/img/Home/aiExpertise/data_science.svg";
import {ReactComponent as Consulting} from "../../../assets/img/Home/aiExpertise/consulting.svg";
import {ReactComponent as AIModel} from "../../../assets/img/Home/aiExpertise/end_to_end.svg";
import {ReactComponent as Integration} from "../../../assets/img/Home/aiExpertise/integration.svg";
import {ReactComponent as Models} from "../../../assets/img/Home/aiExpertise/models.svg";
import {ReactComponent as Gen} from "../../../assets/img/Home/aiExpertise/gen.svg";

export const AIEXPERTISE_DATA = [
    {
        title: 'Data Science',
        desc: 'Data Science extracts insights from data using scientific methods, algorithms, and systems. It combines computer science, statistics, and domain expertise to generate actionable insights that drive business decisions and innovation.',
        Image: DataScience,
    },
    {
        title: 'AI Consulting',
        desc: 'AI Consulting offers expert guidance on implementing and leveraging AI to achieve business goals. It involves assessing systems, developing solutions, and providing ongoing support to ensure successful adoption and integration.',
        Image: Consulting
    },
    {
        title: 'End-to-end AI Model Development',
        desc: 'End-to-end AI Model Development is a service that provides comprehensive support in developing AI models from start to finish. It includes data preparation, model building, training, testing, and deployment.',
        Image: AIModel
    },
    {
        title: 'AI Model Integration and optimization',
        desc: 'AI Model Integration and Optimization integrates AI models into existing systems and optimizes their performance. It involves assessing systems, designing custom integrations, and optimizing model performance for efficient and effective use of AI technology.',
        Image: Integration
    },
    {
        title: 'AI Predictive and Recognition Models',
        desc: 'AI Predictive and Recognition Models use machine learning to recognize patterns and predict future outcomes. Trained with large amounts of data, they can be applied to healthcare, finance, marketing, and other industries.',
        Image: Models
    },
    {
        title: 'Generative AI',
        desc: 'Generative AI is a technology that uses deep learning to generate new content, such as images, text, or music, based on patterns learned from existing data. It mimics human creativity.',
        Image: Gen
    },
]