import React, { useEffect, useRef, useState } from "react";
import classes from "./index.module.scss";
import { data } from "./data";
import { Popup } from "../../components/Modal";
import Expertise from "./Expertise/Expertise";
import Clutch from "../../components/Clutch";
import WhoWeAre from "./WhoWeAre";
import { clear, secondScene } from "./animations/scenes";
import Hero from "./Hero";
import animationClasses from "./animations/index.module.scss";
import { Helmet } from "react-helmet";
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from "../../constants/seo";
import useMediaQuery from "../../customHooks/useMediaQuery";
import ExpertiseRestaurants from "./ExpertiseRestaurants";
import AIExpertise from "./AIExpertise";

const Home = () => {
  const [openModalVideo, toggleModalVideo] = useState(false);
  const matches = useMediaQuery("(min-height: 35em)");

  const openVideo = () => {
    toggleModalVideo(!openModalVideo);
  };

  const [trigger, setTrigger] = useState(false);

  const heroRef = useRef();
  // const firstTl = useRef();
  const secondTl = useRef();
  const clearTl = useRef();

  // const playScene = () => secondTl.current.play();

  // const addListeners = () => {
  //   if (heroRef.current) {
  //     heroRef.current.onwheel = playScene;
  //     heroRef.current.ontouchmove = playScene;
  //   }
  // };

  const removeListeners = () => {
    if (heroRef.current) {
      heroRef.current.onwheel = null;
      heroRef.current.ontouchmove = null;
    }
  };

  // const onCompleteFirstScene = () => {
  //   addListeners();
  // };

  clearTl.current = clear();

  // firstTl.current = firstScene({
  //   circles: `.${animationClasses.circles}`,
  //   buttons: `.${animationClasses.buttons}`,
  //   robotBigFull: `.${animationClasses["robot-big-full"]}`,
  //   text: `.${animationClasses["slide-left"]}`,
  //   robotSmallFull: `.${animationClasses["robot-small-full"]}`,
  //   onComplete: onCompleteFirstScene,
  // });

  secondTl.current = secondScene({
    robotBig: `.${animationClasses["robot-big"]}`,
    robotMiddle: `.${animationClasses["robot-middle"]}`,
    onStart: removeListeners,
  });

  const removeAnimation = () => {
    removeListeners();
    // firstTl.current.kill();
    secondTl.current.kill();
    clearTl.current.play();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTrigger(!trigger);
    return () => {
      removeAnimation();
    };
  }, []);

  useEffect(() => {
    // matches && heroRef.current && firstTl.current.play();
  }, [heroRef.current, matches, trigger]);

  useEffect(() => {
    !matches ? removeAnimation() : window.scrollTo(0, 0);
  }, [matches]);

  return (
    <>
      <Helmet>
        <title>{DEFAULT_TITLE}</title>
        <meta name="title" content={DEFAULT_TITLE} />
        <meta name="description" content={DEFAULT_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Agilistry | Mobile Development Company"
        />
        <meta
          property="og:description"
          content="E2E Mobile iOS/Android applications & Web development from scratch - consulting, design, backend infrastructure, scalable CI/CD processes, testing, and more."
        />
        <meta property="og:url" content="https://agilistrysoft.com" />
        <meta
          property="og:image"
          content="https://api.agilistrysoft.com/wp-content/uploads/2022/10/preview.png"
        />
        <meta property="og:site_name" content="Agilistry" />
        <meta property="og:locale" content="en_US" />
        <script type="application/ld+json">
          {`   {
            "@context": "https://schema.org",
               "@type": "ProfessionalService",
               "name": "Agilistry",
               "description": "Web development company - Agilistry. We are engaged in the creation, automation and testing of web, Android and IOS applications for business.",
               "image": "https://agilistrysoft.com/",
               "@id": "info@agilistrysoft.com",
               "url": "https://agilistrysoft.com/",
               "telephone": "(650) 394-6092",
               "address": {
               "@type": "PostalAddress",
               "streetAddress": "7901 4th Street North",
               "addressLocality": "St. Petersburg, FL, USA St. Petersburg",
               "addressRegion": "FL",
               "postalCode": "33702",
               "addressCountry": "US"
               },
               "openingHoursSpecification": {
               "@type": "OpeningHoursSpecification",
               "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
               ],
               "opens": "00:00",
               "closes": "23:59"
               }
          }`}
        </script>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Cases ✔",
              "item": "https://agilistrysoft.com/cases"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Services ✔",
              "item": "https://agilistrysoft.com/services"  
            },{
              "@type": "ListItem", 
              "position": 3, 
              "name": "Solutions ✔",
              "item": "https://agilistrysoft.com/solutions"  
            },{
              "@type": "ListItem", 
              "position": 4, 
              "name": "Contacts ➢",
              "item": "https://agilistrysoft.com/contact-us"  
            }]}
          `}
        </script>
      </Helmet>
      <Hero iso={data.iso} openVideo={openVideo} heroRef={heroRef} />
      <WhoWeAre data={data.whoWeAre} />
      <ExpertiseRestaurants />
      <Expertise />
      <AIExpertise />
      <Clutch />
      <Popup
        open={openModalVideo}
        openPopup={openVideo}
        component={
          <div className={classes.video}>
            <iframe
              allow="autoplay; fullscreen"
              src="https://www.youtube.com/embed/vsxrFfkYw_A"
              scrolling="no"
              title="Who we are"
            />
          </div>
        }
      />
    </>
  );
};

export default Home;
