import './index.scss'
import {AIEXPERTISE_DATA} from "./data";
import buttonClasses from "../../../components/commons/Button.module.scss";
import {Link} from "react-router-dom";
import { v4 as uuid } from "uuid";




const AIExpertise = () => {
    return(
        <section className="ai_expertise">
            <h3 className="title">
                Expertise in AI field
            </h3>
            <ul className='expertise_grid'>
                {AIEXPERTISE_DATA.map(({Image, title, desc}) => {
                    return(
                        <li key={uuid()}>
                            <Image/>
                            <h4>{title}</h4>
                            <p>{desc}</p>
                        </li>
                    )
                })}
            </ul>
            <div className="expertise_action">
                <Link
                    data-content="READ MORE"
                    to={'/services/ai'}
                    className={`${buttonClasses.button}`}
                >
                    READ MORE
                </Link>
            </div>
        </section>
    )
}

export default AIExpertise