import React, { useDeferredValue, useState } from "react";
import Section from "../commons/Section";
import Container from "../commons/Container";
import { data } from "./data";
import "./index.scss";
import Review from "./Review";
import { v4 as uuid } from "uuid";

const Clutch = () => {
  const [active, setActive] = useState(0);
  const isDisplayReview = (index) => (active === index ? "block" : "none");

  const setActiveSlide = (index) => {
    setActive(index);
  };

  const deferredReviews = useDeferredValue(data);

  return (
    <Section>
      <Container>
        <div className="clutch-root">
          <div className="clutch-wrapper Items_wrapper">
            <div
              className="widgets_wrapper with-nonce"
              data-nonce="EzSHaPoneicBNUih"
              itemScope
              itemType="http://schema.org/Organization"
            >
              <span
                className="rdf-metadata"
                itemProp="name"
                content="Engenious"
              ></span>
              <div id="block-system-main" className="block block-system">
                <div className="big_widget_wrapper big_widget_wrapper_slide">
                  <div className="big_widget_top_pdd">
                    <a
                      className="big_widget_company__name"
                      data-ga-action="num_reviews_click"
                      target="_blank"
                      href="https://clutch.co/profile/engenious?utm_source=widget&utm_medium=4&utm_campaign=widget&utm_content=title"
                      rel="noreferrer"
                    >
                      Agilistry&nbsp;Reviews
                    </a>
                    <a
                      className="logotype"
                      href="https://clutch.co/profile/engenious?utm_source=widget&utm_medium=3&utm_campaign=widget&utm_content=stars#logo"
                      target="_blank"
                      data-ga-action="top_logo_click"
                      rel="noreferrer"
                    >
                      {" "}
                    </a>
                  </div>
                  <div className="provider-profile-rating-widget">
                    <span className="rating">5</span>
                    <div className="reviews-totals-stars">
                      <a
                        href="https://clutch.co/profile/engenious?utm_source=widget&utm_medium=4&utm_campaign=widget&utm_content=stars#reviews"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="fivestar-static-item">
                          <div className="form-item form-type-item">
                            <div className="fivestar-widget-static fivestar-widget-static-vote clearfix">
                              <div className="fivestar">
                                <div className="star star-1 star-odd">
                                  <span className="on">5.000000</span>
                                </div>
                                <div className="star star-2 star-even">
                                  <span className="on"></span>
                                </div>
                                <div className="star star-3 star-odd">
                                  <span className="on"></span>
                                </div>
                                <div className="star star-4 star-even">
                                  <span className="on"></span>
                                </div>
                                <div className="star star-5 star-odd">
                                  <span className="on"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>

                    <span className="reviews-count">
                      <a
                        href="https://clutch.co/profile/engenious?utm_source=widget&utm_medium=4&utm_campaign=widget&utm_content=num_reviews#reviews"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.length} reviews
                      </a>
                    </span>

                    <span
                      className="rdf-metadata"
                      itemProp="AggregateRating"
                      itemScope
                      itemType="http://schema.org/AggregateRating"
                    >
                      <span
                        className="rdf-metadata"
                        itemProp="reviewCount"
                        content="5"
                      ></span>
                      <span
                        className="rdf-metadata"
                        itemProp="ratingValue"
                        content="4.9"
                      ></span>
                    </span>
                  </div>

                  <div className="middle_widget_slider profile-feedback">
                    <div className="reviews-slider">
                      {deferredReviews.map((item, index) => (
                        <Review
                          key={uuid()}
                          data={item}
                          index={index}
                          isDisplayReview={isDisplayReview}
                        />
                      ))}
                    </div>
                    <div className="nav-wrapper">
                      <button
                        className="prev"
                        data-controls="prev"
                        onClick={() =>
                          setActive((active) =>
                            active === 0 ? data.length - 1 : active - 1
                          )
                        }
                      ></button>
                      <button
                        className="next"
                        data-controls="next"
                        onClick={() =>
                          setActive((active) =>
                            active === data.length - 1 ? 0 : active + 1
                          )
                        }
                      ></button>
                      <div className="dots-wrapper">
                        {data.map((_, index) => (
                          <button
                            onClick={() => setActiveSlide(index)}
                            key={uuid()}
                            className={active === index ? "tns-nav-active" : ""}
                            data-nav={0}
                          ></button>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="provider-footer">
                    <a
                      className="logotype"
                      href="https://clutch.co/profile/engenious?utm_source=widget&utm_medium=3&utm_campaign=widget&utm_content=stars#logo"
                      target="_blank"
                      rel="noreferrer"
                    >
                      anchor
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Clutch;
