import { fadeIn, slideIn, timeline, zoomIn, zoomOut, scrollTo } from ".";

const firstScene = ({
  circles,
  robotBigFull,
  text,
  robotSmallFull,
  buttons,
  onComplete = false,
}) => {
  const master = timeline();
  master.set([circles, robotBigFull], {
    autoAlpha: 0,
    scale: 0,
    transformOrigin: "center",
  });
  master.set([text], { x: "-100%", transformOrigin: "center", autoAlpha: 0 });
  master.set([buttons], { x: "100%", transformOrigin: "center", autoAlpha: 0 });
  master.set([robotSmallFull], {
    y: "100%",
    transformOrigin: "center",
    autoAlpha: 0,
  });
  master
    .to("body", { overflow: "hidden" })
    .add(
      fadeIn([circles, robotBigFull], {
        ease: "none",
        duration: 0.05,
      })
    )
    .add(
      zoomIn(circles, {
        ease: "power4.out",
        duration: 0.5,
      })
    )
    .add(
      zoomIn(robotBigFull, {
        ease: "back.out(1.7)",
        duration: 0.6,
      })
    )
    .add(
      fadeIn([text, robotSmallFull, buttons], {
        ease: "none",
        duration: 0.3,
      })
    )
    .add(
      slideIn([text, robotSmallFull, buttons], {
        ease: "none",
        duration: 0.3,
      }),
      "<"
    )
    .eventCallback("onComplete", onComplete);

  return master;
};

function secondScene({ robotBig, robotMiddle, onStart }) {
  const master = timeline();
  master.set([robotBig], {
    transformOrigin: "220px bottom",
  });
  master.set([robotMiddle], {
    scale: 0,
    transformOrigin: "200px bottom",
    y: 80,
  });
  master
    .eventCallback("onStart", onStart)
    .add(
      zoomOut(robotBig, {
        ease: "power4.out",
        duration: 1,
      })
    )
    .add(
      scrollTo(window, {
        duration: 0.5,
        scrollTo: { y: "#robot-middle", offsetY: 120 },
      }),
      "-=0.3"
    )
    .add(
      fadeIn(robotMiddle, {
        ease: "none",
        duration: 0.05,
      })
    )
    .add(
      zoomIn(robotMiddle, {
        ease: "power4.out",
        duration: 0.3,
      })
    )
    .add(
      slideIn(robotMiddle, {
        ease: "power4.out",
        duration: 0.3,
      }),
      "<"
    )
    .add(
      zoomIn(robotBig, {
        ease: "power4.out",
        duration: 0.6,
      })
    )
    .to("body", { overflow: null });
  return master;
}

const clear = () => {
  const master = timeline().to("body", { overflow: null });
  return master;
};

export { firstScene, clear, secondScene };
